import React from "react"

import { SuccessMessage } from "../information/successMessage.component"
import { useTranslation } from "../translation/translate.component"
import Block from "../../components/block/block.component"

export default function DesinscriptionEmailPage () {
  const { t } = useTranslation()

  return (
    <Block blockTitle={t(`common:unsuscribe.section_unsuscribe_primary`)} blockTitleSeo="h1">
      <SuccessMessage>{t(`common:unsuscribe.section_unsuscribe_success_message`)}</SuccessMessage>
    </Block>
  )
}

DesinscriptionEmailPage.propTypes = {}
