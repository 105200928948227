import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

import { colors, fontSizes } from "../structure/theme"
import { flexCenter } from "../structure/theme/styles.component"

const Text = styled.div`
  color: ${colors(`valid`)};
  font-weight: 900;
  margin-left: 10px;
  font-size: ${fontSizes(`large`)};

  ${breakpoint(`small`)`
    margin-left: 30px;
  `}
`
const CenterCard = styled.div`
  ${flexCenter};
`

export function SuccessMessage ({ children }) {
  return (
    <CenterCard>
      <Text data-testid={arguments[0][`data-testid`] || undefined}>{children}</Text>
    </CenterCard>
  )
}

SuccessMessage.propTypes = {
  children: PropTypes.node.isRequired,
}
